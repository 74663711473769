import { init_language } from '@/utils/language'

const config = {
  host  : window.location.hostname,
  lang  : init_language('de'),
  email : 'support@mobe-helpcenter.com',
  set_email (src: string): string {
    return src.replaceAll('info@mobefind.com', this.email)
  },
  gtag: ''
}

export { config }
  
